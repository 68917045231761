import * as React from 'react'

import Quote from '../../assets/icons/quote.svg'
import CodeBlock from './CodeBlock'

const Components = {
  wrapper: ({ children }: { children: React.ReactNode }) => (
    <div className="prose prose-stone">{children}</div>
  ),
  code: CodeBlock,
  pre: (preProps: any) => <pre {...preProps} />,
  h1: (props: any) => (
    <h1 className="text-4xl leading-tight font-bold my-12" {...props} />
  ),
  h2: (props: any) => <h2 className="text-3xl font-bold my-8" {...props} />,
  h3: (props: any) => <h3 className="text-2xl font-semibold my-6" {...props} />,
  h4: (props: any) => <h4 className="text-xl font-semibold my-4" {...props} />,
  h5: (props: any) => <h5 className="text-lg font-semibold my-3" {...props} />,
  h6: (props: any) => <h6 className="text-md font-semibold my-2" {...props} />,
  // The fact that you have to do this suggests major issues with this approach
  p: (props: any) => (
    <div className="font-normal leading-loose antialiased mb-4" {...props} />
  ),
  img: (props: any) => (
    <div className="w-full flex align-center items-center my-8 rounde-md">
      <img className="mx-auto" {...props} />
    </div>
  ),
  blockquote: (props: any) => (
    <div>
      <blockquote
        className="relative px-6 text-xl italic border-l-4 bg-gray-100 text-gray-600 border-gray-500 quote"
        {...props}
      />
    </div>
  ),
}

export default Components
// Open Sans

import * as React from 'react'

import useLocalStorage from '../utils/hooks/useLocalStorage'

type ThemeContextProps = {
  // todo: the theme values should be strictly typed based on the defined themes
  theme: string
  switchTheme: Function
}

const ThemeContext = React.createContext<Partial<ThemeContextProps>>({
  theme: '',
  switchTheme: (theme: string) => {
    return theme
  },
})

interface IThemContextProviderProps {
  children?: any
  props?: any
}

const ThemeContextProvider = ({
  children,
  ...props
}: IThemContextProviderProps) => {
  // Set the default theme to dark-mode
  // const [theme, setTheme] = useLocalStorage('theme', null)
  const [theme, setTheme] = useLocalStorage('theme', null)

  // React.useEffect(() => {
  //   function loadTheme() {
  //     const theme = localStorage.getItem('theme')
  //     return theme || 'dark'
  //   }
  //   setTheme(loadTheme())
  // }, [])

  // Update the value in local storage whenever theme changes
  // React.useEffect(() => {
  //   localStorage.setItem('theme', theme)
  // }, [theme])

  const switchTheme = (newTheme: string) => {
    // eslint-disable-next-line no-undef
    const root = document.body
    root.classList.remove(theme)
    root.classList.add(newTheme)
    setTheme(newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }} {...props}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => {
  return React.useContext(ThemeContext)
}

export default ThemeContextProvider

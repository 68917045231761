import * as React from 'react'

import useSound from 'use-sound'
import useLocalStorage from '../utils/hooks/useLocalStorage'

// TODO: investigate why ES6 imports do not work for mp3's with typescript - explain in blog post
// @ts-ignore
import cartoonClickOnSfx from '../static/sounds/ESM_App_Menu_Button_6_Game_Synth_Electronic_Cartoon_User_Interface_Switch.wav'
// @ts-ignore
import cartoonClickOffSfx from '../static/sounds/ESM_App_Menu_Button_5_Game_Synth_Electronic_Cartoon_User_Interface_Switch.wav'
// @ts-ignore
import darkModeOnSfx from '../static/sounds/ESM_Suck_Pop_Message1_Notification_Synth_Electronic_Particle_Cute_Cartoon.wav'
// @ts-ignore
import darkModeOffSfx from '../static/sounds/ESM_Square_Pop_Game_Organic_Cartoon.wav'
// @ts-ignore
import popSfx from '../static/sounds/ESM_POP_Game_Organic_Cartoon.wav'

type SoundModeContextProps = {
  soundMode: boolean
  setSoundMode: Function
  playCartoonClickOn: Function
  playCartoonClickOff: Function
  playDarkModeOn: Function
  playDarkModeOff: Function
  playPopSound: Function
}

const SoundModeContext = React.createContext<Partial<SoundModeContextProps>>({
  soundMode: false,
  setSoundMode: (soundMode: boolean) => {
    return soundMode
  },
})

interface ISoundModeContextProviderProps {
  children?: any
  props?: any
}

const SoundModeContextProvider = ({
  children,
  ...props
}: ISoundModeContextProviderProps) => {
  const [soundMode, setSoundMode] = useLocalStorage('soundMode', null)

  const [playCartoonClickOn] = useSound(cartoonClickOnSfx)
  const [playCartoonClickOff] = useSound(cartoonClickOffSfx)
  const [playDarkModeOn] = useSound(darkModeOnSfx)
  const [playDarkModeOff] = useSound(darkModeOffSfx)
  const [playPopSound] = useSound(popSfx)

  return (
    <SoundModeContext.Provider
      value={{
        soundMode,
        setSoundMode,
        playCartoonClickOn,
        playCartoonClickOff,
        playDarkModeOn,
        playDarkModeOff,
        playPopSound,
      }}
      {...props}
    >
      {children}
    </SoundModeContext.Provider>
  )
}

export const useSoundModeContext = () => {
  return React.useContext(SoundModeContext)
}

export default SoundModeContextProvider

import * as React from 'react'

import { MDXProvider } from '@mdx-js/react'

import './src/styles/global.css'

import ThemeContextProvider from './src/context/theme-context'
import SoundModeContextProvider from './src/context/sound-context'

import MDXComponents from './src/components/MDX'

// https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
const components = {
  ...MDXComponents,
}

// TODO: in production application, have to copy this code into the gatsby-ssr file so that the MDXProvider is used in the build for the pages
export const wrapRootElement = ({ element }) => {
  return (
    <ThemeContextProvider>
      <SoundModeContextProvider>
        <MDXProvider components={components}>{element}</MDXProvider>
      </SoundModeContextProvider>
    </ThemeContextProvider>
  )
}
